.tagline-copy-block {
  &__headline {
    @apply mb-12;
  }

  &__main {
    @screen lg {
      @apply flex gap-12;

      p {
        @apply mb-0;
      }

      > div {
        @apply w-1/2;
      }

      &--right {
        @apply flex-row-reverse;
      }
    }
  }

  &__tagline {
    @apply text-xl;

    @screen md {
      @apply text-2xl;
    }

    @screen lg {
      @apply text-3xl;
    }
  }
}
