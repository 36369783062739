.button,
button {
  @apply m-0 p-0 inline-block border-0 border-solid bg-transparent cursor-pointer;
  @apply whitespace-no-wrap text-base no-underline;
}

.button {
  @apply bg-transparent border-b-7 border-primary rounded-none shadow-none;
  @apply font-bold font-secondary text-standard-black uppercase;
  @apply p-0;
  @apply relative;
  @apply transition-colors duration-300;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); */

  &::after {
    @apply bg-contain bg-center bg-no-repeat;
    @apply w-5 h-5;
    @apply block absolute;
    @apply transition-all duration-300;
    @apply opacity-0;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    content: '';
    background-image: url('../img/arrow.svg');
  }

  &:hover {
    &::after {
      @apply opacity-100;
      left: calc(100% + 0.25rem);
    }
  }

  &:disabled {
    @apply cursor-not-allowed;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &--white {
    @apply text-standard-white;
  }

  &--secondary {
    @apply border-b-0;
    /* @apply bg-transparent border-b-4 border-primary rounded-none shadow-none; */
    /* @apply font-semibold text-text; */
    /* @apply p-0; */
    /* @apply relative; */

    /* &:hover {
      @apply text-text bg-transparent border-primary-active;

      &::after {
        left: calc(100% + 0.5rem);
      }
    } */
  }

  @screen md {
    @apply text-xl;
  }

  @screen lg {
    @apply text-2xl;
  }
}
