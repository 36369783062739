.image-block {
  &__inner {
    @apply w-full max-w-6xl mx-auto;
  }

  &__image {
    @apply w-full;
  }

  .image-block__inner {
    .container--full-width & {
      @apply max-w-none;
    }
  }
}
