.featured-work {
  &__cta {
    @apply text-center;

    /* .button {
      @apply text-xl;
    } */
  }

  /* @screen md {
    &__cta {
      .button {
        @apply text-2xl;

        &::after {
          @apply w-6 h-6;
        }
      }
    }
  } */

  /* @screen lg {
    &__cta {
      .button {
        @apply text-3xl;

        &::after {
          @apply w-8 h-8;
        }
      }
    }
  } */
}

.featured-work-example {
  @apply bg-cover bg-center;
  @apply py-20;
  @apply text-standard-white;

  &:not(:last-of-type) {
    @apply mb-8;
  }

  &__eyebrow {
    > * {
      @apply text-xs font-secondary font-bold uppercase;
      @apply mb-4;
      text-shadow: 0 0 16px rgba(0, 0, 0, 0.7);
    }
  }

  &__tagline {
    @apply text-4xl font-primary font-bold leading-tight;
    @apply mb-12;
    @apply max-w-md;
    text-shadow: 0 3px 32px rgba(0, 0, 0, 0.6);
  }

  &__work-link {
    @apply mb-8;

    .button {
      @apply text-standard-white;

      &:hover {
        @apply text-primary-active;
      }
    }
  }

  &__category-link {
    a {
      @apply font-secondary font-bold text-standard-white uppercase text-base;

      &:hover {
        @apply text-primary-active;
      }
    }
  }

  @screen md {
    &__eyebrow {
      > * {
        @apply text-lg;
      }
    }

    &__tagline {
      @apply text-5xl;
    }

    &__work-link,
    &__category-link {
      a {
        @apply text-xl;
      }
    }
  }

  @screen lg {
    @apply flex flex-col;
    @apply py-32;
    min-height: 45rem;

    .container {
      @apply flex flex-col flex-grow w-full;
    }

    &__inner {
      @apply flex flex-col flex-grow;
    }

    &__copy {
      @apply flex-grow;
    }

    &__eyebrow {
      > * {
        @apply text-xl;
      }
    }

    &__tagline {
      @apply text-6xl;
      @apply max-w-4xl;
    }

    &__actions {
      @apply flex;
      @apply w-full;
    }

    &__work-link {
      @apply pr-20;
    }
  }
}
