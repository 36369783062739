html,
body {
  font-size: 16px;
  line-height: 1.6;
}

body {
  @apply tracking-wide font-light font-primary;
  @apply text-sm;

  @screen md {
    @apply text-base;
  }

  @screen lg {
    @apply text-lg;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--primary-font);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
  @apply leading-tight;
}

h1,
.h1 {
  @apply text-4xl;
}

h2,
.h2 {
  @apply text-2xl;
}

h3,
.h3 {
  @apply text-xl;
}

h4,
.h4 {
  @apply text-base;
}

h5,
.h5 {
  @apply text-base;
}

h6,
.h6 {
  @apply text-sm;
}

p {
  @apply mt-0 mb-4;
}

a,
.link {
  @apply no-underline text-primary;
  @apply transition-colors duration-300;
  @apply border-0;

  &:hover {
    @apply text-primary-active;
  }
}

@screen md {
  h1,
  .h1 {
    @apply text-5xl;
  }

  h2,
  .h2 {
    @apply text-4xl;
  }

  h3,
  .h3 {
    @apply text-3xl;
  }

  h4,
  .h4 {
    @apply text-2xl;
  }

  h5,
  .h5 {
    @apply text-2xl;
  }

  h6,
  .h6 {
    @apply text-lg;
  }
}

@screen lg {
  h1,
  .h1 {
    @apply text-6xl;
  }

  h2,
  .h2 {
    @apply text-5xl;
  }

  h3,
  .h3 {
    @apply text-4xl;
  }

  h4,
  .h4 {
    @apply text-3xl;
  }

  h5,
  .h5 {
    @apply text-3xl;
  }

  h6,
  .h6 {
    @apply text-xl;
  }
}
