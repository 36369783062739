$gap: 0.5rem;

.repeatable-images {
  @apply overflow-x-hidden;

  &__image-container {
    &:not(:last-of-type) {
      @apply mb-4;
    }
  }

  &__image {
    @apply block w-full mx-auto;
  }

  .splide {
    &__track {
      @apply overflow-visible;
    }

    &__list {
      @apply items-center;
    }
  }

  @screen md {
    /* @apply -mb-4; */

    &:not(.repeatable-images--slider) {
      .splide {
        @apply w-full;

        &__list {
          @apply w-full;
          @apply flex items-center justify-center flex-wrap;
        }

        &__slide {
          @apply mx-2 mb-4;
          flex-basis: calc(50% - 1rem);
        }
      }
    }
  }

  @screen lg {
    @apply -mx-2;

    &:not(.repeatable-images--slider) {
      @for $i from 2 to 5 {
        &.repeatable-images--$(i) {
          .splide {
            &__slide {
              flex-basis: calc((100% / $i) - 1rem);
            }
          }
          .repeatable-images__image-container {
            flex-basis: calc((100% / $i) - $gap);
          }
        }
      }
    }
  }
}
