.work {
  &__headline {
    @apply mb-8;

    h1 {
      @apply text-3xl;
    }
  }

  &__tags {
    @apply mb-6 uppercase tracking-tight;
    @apply font-secondary;
  }

  &__copy {
    @apply max-w-3xl;
  }

  &__tagline {
    > * {
      @apply text-2xl;
      @apply font-light;
    }
  }

  &__projects-text {
    @apply mb-8;
    h2 {
      @apply mb-8;
    }
  }

  &__projects-links {
    @apply list-none m-0 p-0;
    @apply w-full;
  }

  &__project {
    @apply w-full;

    &:last-of-type {
      @apply mb-0;
    }
  }

  @screen md {
    &__projects-links {
      @apply flex gap-4;
    }

    &__project {
      @apply w-1/2 m-0 p-0;
      flex-basis: auto;

      &:not(:last-of-type) {
        @apply mb-0;
      }
    }
  }

  @screen lg {
    &__columns {
      @apply flex justify-between gap-12;
      @apply my-16;
    }

    &__left,
    &__right {
      @apply w-1/2;
    }

    &__headline {
      h1 {
        @apply text-5xl;
      }
    }

    &__tags {
      @apply text-base;
    }

    &__tagline {
      > * {
        @apply text-4xl;
      }
    }

    &__projects {
      @apply flex gap-8;
    }

    &__projects-text {
      @apply w-1/3 mb-0;
      @apply flex flex-col justify-between items-start;
    }
  }
}
