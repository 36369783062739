.team {
  @apply overflow-x-hidden;

  &__headline {
    @apply mb-6;
  }

  &__members {
    @apply relative;
  }

  .splide {
    &__track {
      @apply overflow-visible;
    }

    &__slide {
      @apply opacity-50;
      @apply transition-opacity duration-200;

      &.is-active {
        @apply opacity-100;
      }
    }

    &__arrows {
      @apply pointer-events-none hidden;
      @apply absolute inset-y-0 w-screen;
      left: 50%;
      transform: translateX(-50%);
    }

    &__arrow {
      @apply pointer-events-auto;
      @apply absolute;
      @apply bg-transparent opacity-100;
      @apply w-10 h-10;
      @apply transition-all duration-300;
      top: 50%;
      transform: translateY(-50%);

      svg {
        @apply transition-all duration-300;
        @apply w-10 h-10;
      }

      &--next {
        right: 2rem;
      }

      &--prev {
        left: 2rem;
      }

      &:hover {
        @apply w-12 h-12;

        svg {
          @apply w-12 h-12;
        }

        &.splide__arrow--next {
          right: 1rem;
        }

        &.splide__arrow--prev {
          left: 1rem;
        }
      }
    }
  }

  @screen lg {
    &__inner {
      @apply flex;
    }

    .splide {
      &__arrows {
        @apply block;
      }
    }
  }
}

.team-member {
  @apply bg-cover bg-right;
  @apply h-56 p-4;
  @apply text-standard-white;
  @apply relative;
  @apply border border-solid border-transparent;
  @apply transition-all duration-300;

  &__inner {
    @apply relative z-10;
    @apply h-full;
    @apply flex flex-col;
  }

  &__info-container {
    @apply w-full flex-grow-0 flex-shrink;
  }

  &__info {
    @apply w-1/2;
  }

  &__close {
    @apply opacity-0 pointer-events-none;
    @apply transition-opacity duration-300;
    @apply absolute top-0 right-0;
  }

  &__name {
    > * {
      @apply font-bold;
    }
  }

  &__position {
    @apply text-sm font-secondary;

    p {
      @apply mb-2;
    }
  }

  &__bio {
    @apply flex-grow flex-shrink;
    @apply overflow-y-auto;
    @apply text-2xs;
    @apply opacity-0 pointer-events-none;
    @apply transition-opacity duration-300;
  }

  &__actions {
    @apply absolute left-0 bottom-0;

    > .link {
      @apply text-standard-white font-bold font-secondary uppercase;
    }
  }

  &::before {
    @apply absolute inset-0;
    @apply bg-standard-white;
    @apply opacity-0 pointer-events-none;
    @apply transition-opacity duration-300;
    content: '';
  }

  &--show-bio {
    @apply border-primary;
    @apply text-standard-black;

    .team-member {
      &__bio,
      &__close {
        @apply opacity-100 pointer-events-auto;
      }
    }

    &::before {
      @apply opacity-100;
    }
  }

  @screen sm {
    @apply h-80 p-8;
  }

  @screen md {
    height: 30rem;

    &__bio {
      @apply text-xs;
    }
  }

  @screen lg {
    height: 35rem;

    &__bio {
      @apply text-sm;
    }

    &__position {
      @apply text-base;
    }
  }
}
