.article {
  &__tags {
    @apply my-12 uppercase;
  }

  @screen lg {
    &__tags {
      @apply text-base;
    }
  }
}
