.hero {
  @apply pt-40 pb-24 px-8;
  @apply text-standard-white font-bold text-center;
  @apply bg-cover bg-center;

  &__container {
    @apply max-w-6xl mx-auto;
  }

  &__headline {
    @apply mb-4;

    > * {
      text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
    }

    .homepage & {
      > * {
        font-size: 3.5rem;
      }
    }
  }

  &__copy {
    > * {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }
  }

  .homepage & {
    + .container {
      margin-top: 4rem;
    }
  }

  @screen lg {
    @apply flex flex-col justify-center;
    @apply pt-32;
    min-height: 40rem;

    &__headline {
      > * {
        @apply text-7xl;
      }

      .homepage & {
        > * {
          @apply text-7xl;
        }
      }

      .about & {
        > * {
          @apply text-6xl;
        }
      }
    }

    &__copy {
      @apply text-2xl;

      .homepage & {
        @apply max-w-3xl mx-auto;

        > * {
          @apply text-3xl;
        }
      }
    }
  }
}
