$gap: 0.5rem;

.image-collage {
  &__image {
    @apply mb-4;
    @apply flex justify-center items-center;

    &--vertical {
      @apply h-80;

      img {
        @apply h-full w-full object-contain;
      }
    }
  }

  &__row {
    @apply flex flex-col;

    &--reverse {
      @apply flex-col-reverse;
    }
  }

  @screen md {
    &__row {
      @apply flex-row justify-between items-stretch;

      &--reverse {
        @apply flex-row-reverse;
      }

      &--oneLargeOneSmall {
        .image-collage__image {
          &:first-of-type {
            flex-basis: calc(67% - $gap);
          }

          &:last-of-type {
            flex-basis: calc(33% - $gap);
          }
        }
      }

      &--twoEqualWidth {
        .image-collage__image {
          flex-basis: calc(50% - $gap);
        }
      }

      &--twoMediumOneSmall {
        .image-collage__image {
          &:last-of-type {
            flex-basis: calc(17% - $gap);
          }

          &:not(:last-of-type) {
            flex-basis: calc(41.5% - $gap);
          }
        }
      }

      &--oneLargeTwoStacked {
        > div {
          &:first-of-type {
            flex-basis: calc(67% - $gap);
          }

          &:last-of-type {
            @apply flex flex-col;
            flex-basis: calc(33% - $gap);

            .image-collage__image {
              flex: 1 1 calc(50% - $gap);
            }
          }
        }
      }

      &--oneLargeFourStacked {
        > div {
          &:first-of-type {
            flex-basis: calc(30% - $gap);
          }

          &:last-of-type {
            @apply flex flex-wrap justify-start items-start;
            flex-basis: calc(70% - $gap);

            .image-collage__image {
              width: calc(50% - $gap);
            }
          }
        }
      }
    }

    &__image {
      &--vertical {
        @apply h-auto;
      }
    }
  }
}
