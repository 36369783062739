.copy-block {
  @apply font-bold;
  @apply max-w-4xl;

  p {
    @apply mb-6;
  }

  strong {
    @apply font-bold text-primary;
  }

  &__cta {
    @apply my-12;
  }

  @screen lg {
    @apply py-10;
  }
}
