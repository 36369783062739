.navbar {
  @apply flex justify-between items-center;
  @apply py-4 px-8;
  @apply bg-navbar-background;
  @apply fixed inset-x-0 top-0 z-50;

  &__home-link {
    @apply border-0;
    @apply flex items-center;
  }

  &__logo-primary {
    @apply h-8;
  }

  &__logo-secondary {
    @apply opacity-100;
    @apply transition-opacity duration-200;
    @apply ml-2;

    &--scrolled {
      @apply opacity-0;
    }
  }

  &__hamburger {
    @apply text-primary font-secondary font-bold uppercase flex items-center;
    @apply transition-colors duration-300;

    * {
      @apply inline-flex;
    }

    span {
      @apply mr-2;
    }

    &:hover {
      @apply text-primary-active;
    }
  }

  &__menu {
    @apply fixed inset-0 z-50;
    @apply bg-cover bg-center bg-no-repeat;
    @apply opacity-0 pointer-events-none;
    @apply transition-opacity duration-300;

    &--open {
      @apply opacity-100 pointer-events-auto;
    }
  }

  &__menu-close {
    @apply block;
  }

  &__menu-top {
    @apply flex items-center justify-between;
    @apply py-4 px-8;
  }

  &__nav-list {
    @apply list-none text-right px-12;
  }

  &__nav-list-item {
    @apply font-secondary font-bold text-3xl uppercase;

    a {
      @apply inline-block text-standard-black leading-none;
      @apply border-b-7 border-solid border-transparent;

      &:hover {
        @apply border-primary;
      }
    }
  }

  @screen md {
    &__nav-list-item {
      @apply text-4xl;
    }
  }

  @screen lg {
    &__nav-list-item {
      @apply text-6xl;
    }
  }
}
