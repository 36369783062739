blockquote {
  @apply m-0 mb-8;
  @apply text-3xl;

  + figcaption {
    @apply text-sm tracking-tight font-secondary;
  }

  @screen md {
    + figcaption {
      @apply text-base;
    }
  }
}

.testimonial {
  @apply max-w-4xl w-full relative;
  @apply my-12 mx-auto;
  @apply text-center;

  &__author-name {
    @apply font-bold;
  }

  &__quote {
    @apply font-bold;
  }

  &::before,
  &::after {
    @apply block absolute;
    @apply font-secondary text-7xl leading-none text-standard-black;
    @apply not-italic;
  }

  &::before {
    content: '“';
    top: -2.5rem;
    left: -1rem;
  }

  &::after {
    content: '”';
    top: 100%;
    right: -1rem;
  }

  @screen md {
    &::before,
    &::after {
      @apply text-10xl;
    }

    &::before {
      top: -3rem;
      left: auto;
      right: calc(100% + 2rem);
    }

    &::after {
      top: calc(100% - 1rem);
      right: auto;
      left: calc(100% + 2rem);
    }
  }
}

.container--dark-bg {
  .testimonial {
    @apply text-standard-white;

    &::before,
    &::after {
      @apply text-standard-white;
    }
  }
}
