.footer {
  @apply px-8 pt-8 pb-4;
  @apply bg-footer-background text-footer-text;

  &--gradient {
    background: linear-gradient(
      140deg,
      var(--footer-background-secondary-color),
      var(--footer-background-color)
    );
  }

  &__headline {
    @apply text-3xl mb-8;
  }

  &__block {
    @apply mb-8;
  }

  &__block-headline {
    @apply uppercase;

    * {
      @apply font-secondary;
    }
  }

  &__block-copy {
    @apply text-xs;

    a {
      @apply text-white border-solid border-b-4 border-primary;

      &:hover {
        @apply text-primary border-primary;
      }
    }
  }

  &__social {
    @apply my-8;
  }

  &__social-list {
    @apply flex justify-center items-center list-none p-0;
  }

  &__social-list-item {
    &:not(:last-of-type) {
      @apply mr-4;
    }
  }

  &__social-link {
    @apply text-footer-text border-0;
  }

  &__copyright {
    @apply font-secondary text-xs text-center;

    a {
      @apply text-footer-text underline border-0;

      &:hover {
        @apply text-primary;
      }
    }
  }

  @screen md {
    &__block-headline {
      > * {
        @apply text-xl;
      }
    }

    &__block-copy {
      @apply text-base;
    }
  }

  @screen lg {
    @apply pt-24 pb-8;

    &__headline {
      @apply text-5xl mb-12;
    }

    &__main {
      @apply flex items-end justify-between;
      @apply mb-4;

      > * {
        flex-basis: calc(50% - 0.5rem);
      }
    }

    &__block {
      @apply mb-16;
    }

    &__block-headline {
      > * {
        @apply text-2xl;
      }
    }

    &__block-copy {
      @apply text-lg;
    }

    &__social {
      @apply mb-12;
    }

    &__social-list {
      @apply justify-end;
    }
  }
}
