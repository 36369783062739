.category-list {
  @apply mx-auto max-w-7xl;

  &__categories-container {
    @apply pl-8 pb-4;
  }

  &__categories {
    @apply w-full pt-4 pb-2;
    @apply flex overflow-x-auto;
  }

  &__category {
    @apply pr-4;

    &:last-of-type {
      @apply pr-8;
    }
  }

  &__actions {
    @apply text-center;
  }

  &__category-button {
    @apply font-bold font-secondary uppercase text-lg;
    @apply transition-colors duration-300;

    &--active {
      @apply border-b-4 border-primary;
    }

    &:hover {
      @apply text-primary;
    }
  }

  &__items-container {
    @apply px-8;
  }

  &__items {
    @apply flex flex-col;
    @apply list-none m-0 p-0;
  }

  @screen md {
    &__categories {
      @apply justify-center;
    }

    &__category-button {
      @apply text-2xl;
    }

    &__items {
      @apply flex-row flex-wrap;
      @apply -mx-2;
    }
  }
}

.category-item {
  @apply block relative w-full border-0;
  @apply bg-cover bg-center;
  @apply mb-8;
  padding-top: 120%;

  &__link {
    @apply absolute inset-0;
    @apply flex;
    @apply border-0 no-underline;

    &:hover {
      @apply border-0 no-underline;
    }
  }

  &__inner {
    @apply p-4 w-full;
    @apply flex flex-col justify-end;
    @apply text-standard-white;
    background: rgba(0, 0, 0, 0.25);
  }

  &__title {
    @apply font-primary font-semibold text-xl;

    p {
      @apply mb-0;
    }
  }

  &:not(:last-of-type) {
    @apply mb-8;
  }

  @screen md {
    @apply mx-2;
    padding-top: 40%;
    flex-basis: 100%;

    &:not(:first-of-type) {
      padding-top: 50%;
      flex-basis: calc(50% - 1rem);
    }
  }

  @screen lg {
    padding-top: 35%;

    &:not(:first-of-type) {
      padding-top: 30%;
    }

    &:nth-of-type(n + 6) {
      flex-basis: calc((100% / 3) - 1rem);
      padding-top: 30%;
    }
  }
}
