.capabilities {
  &__top {
    @apply mb-4;
  }

  &__headline {
    @apply mb-8;
  }

  /* &__copy {
    @apply font-bold;
  } */

  /* &__tabs-container {
    @apply -mr-8;
  }

  &__tabs {
    @apply w-full pt-4 pb-2;
    @apply flex overflow-x-auto;
  }

  &__tab {
    @apply pr-4;

    &:last-of-type {
      @apply pr-8;
    }
  }

  &__tab-button {
    @apply font-bold font-secondary uppercase;
    @apply border-b-4 border-transparent;
    @apply transition-colors duration-300;

    &--active {
      @apply border-primary;
    }

    &:hover {
      @apply text-primary;
    }
  } */

  @screen md {
    &__top {
      @apply max-w-3xl;
    }

    &__headline {
      > * {
        @apply text-4xl;
      }
    }

    /* &__copy {
      @apply text-xl;
    } */

    /* &__tabs {
      @apply flex-wrap;
      @apply pb-0;
    }

    &__tab {
      @apply pr-24 mb-6;
    }

    &__tab-button {
      @apply text-2xl;
    } */
  }

  @screen lg {
    &__headline {
      > * {
        @apply text-5xl;
      }
    }

    /* &__copy {
      @apply text-2xl;
    } */
  }
}

.capability {
  /* blockquote {
    @apply ml-10 my-4 mr-0 pl-0;

    &::before {
      left: auto;
      right: calc(100% + 0.5rem);
    }

    footer {
      @apply text-xs;
    }
  } */

  &__title {
  }

  &__quote {
    @apply mb-4;
  }

  &:not(:last-of-type) {
    @apply mb-8;
  }

  @screen md {
    @apply flex justify-between items-center;

    &__text,
    &__image-container {
      @apply w-1/2;
    }

    &__text {
      @apply pl-8;
    }

    &:not(:last-of-type) {
      @apply mb-0;
    }

    &:nth-of-type(even) {
      @apply flex-row-reverse;

      &__text {
        @apply pl-0 pr-8;
      }
    }

    /* blockquote {
      @apply text-3xl leading-tight;
      flex-basis: calc(65% - 5rem);

      footer {
        @apply text-lg;
      }
    } */
  }
}
