html,
body {
  height: 100%;
}

body {
  @apply bg-site-background text-text;
  @apply overflow-x-hidden;
}

img {
  max-width: 100%;
}

.container {
  @apply w-full max-w-6xl mx-auto;

  &--dark-bg {
    @apply text-standard-white;
  }

  &--x-spacing {
    &-sm {
      @apply px-4;
    }

    &-md {
      @apply px-8;
    }

    &-lg {
      @apply px-12;
    }

    &-xl {
      @apply px-16;
    }

    &-2xl {
      @apply px-20;
    }
  }

  &--y-spacing {
    &-2xs {
      @apply my-4;
    }

    &-xs {
      @apply my-8;
    }

    &-sm {
      @apply my-12;
    }

    &-md {
      @apply my-16;
    }

    &-lg {
      @apply my-20;
    }

    &-xl {
      @apply my-24;
    }

    &-2xl {
      @apply my-28;
    }
  }

  &--secondary-bg {
    @apply my-0;

    &.container--y-spacing {
      &-2xs {
        @apply py-4;
      }

      &-xs {
        @apply py-8;
      }

      &-sm {
        @apply py-12;
      }

      &-md {
        @apply py-16;
      }

      &-lg {
        @apply py-20;
      }

      &-xl {
        @apply py-24;
      }

      &-2xl {
        @apply py-28;
      }
    }
  }

  &--full-width {
    @apply px-0 mx-0 max-w-none;
  }
}

.splide {
  cursor: grab;

  &__arrow {
    background: transparent;
    font-size: 1.5rem;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &:active {
    cursor: grabbing;
  }
}
