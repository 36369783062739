.current-thoughts {
  @apply overflow-x-hidden;

  &__headline {
    @apply mb-6;
  }

  &__articles {
    @apply mb-4;
  }

  .splide {
    &__track {
      @apply overflow-visible;
    }
  }

  @screen md {
    &__articles {
      .splide {
        @apply w-full;

        &__list {
          @apply w-full;
          @apply flex flex-wrap justify-between;
        }

        &__slide {
          flex-basis: calc(50% - 0.25rem);
        }
      }
    }

    &__article {
      @apply w-full;
    }
  }
}

.article-card {
  @apply w-full mb-6;

  &__image {
    @apply w-full mb-4;
    @apply relative;
    padding-top: 60%;

    img {
      @apply absolute inset-0;
      @apply w-full h-full;
      @apply object-cover;
    }
  }

  &__title {
    > * {
      @apply font-bold text-text mb-4;
    }
  }

  &__tagline {
    @apply font-secondary;
  }
}
