[data-cartapus] {
  @apply opacity-0 transform translate-y-4;
  @apply transition-all duration-700;
}

[data-cartapus-animate-from='left'] {
  @apply translate-y-0 -translate-x-4;
}

[data-cartapus-animate-from='right'] {
  @apply translate-y-0 translate-x-4;
}

[data-cartapus='visible'] {
  @apply opacity-100 translate-y-0 translate-x-0;
}
