.videos {
  &__video {
    @apply relative w-full;
    padding-top: 56.25%;

    &:not(:last-of-type) {
      @apply mb-8;
    }
  }

  iframe {
    @apply absolute inset-0;
    @apply w-full h-full;
  }
}
