.testimonial-slider {
  .splide {
    &__pagination {
      &__page {
        @apply opacity-100;

        &.is-active {
          @apply bg-primary;
        }
      }
    }
  }
}
