.current-openings {
  &__none {
    @apply -mx-8;
  }
}

.current-opening {
  &__headline {
    @apply mb-4;

    > * {
      @apply text-xl font-bold;
    }
  }

  &__excerpt,
  &__more {
    @apply max-w-4xl;
  }

  &__more {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &__actions {
    @apply mt-4;
  }

  &__action {
    @apply font-bold text-base font-primary;
  }

  &__caret {
    @apply text-primary ml-2;

    svg {
      @apply transform rotate-180;
      @apply transition-transform duration-300;
    }
  }

  &:not(:last-of-type) {
    @apply border-t-0 border-l-0 border-r-0 border-b border-solid border-primary;
    @apply pb-16 mb-16;
  }

  &--open {
    .current-opening__caret svg {
      @apply rotate-0;
    }
  }

  @screen md {
    &__headline {
      > * {
        @apply text-2xl;
      }
    }

    &__action {
      @apply text-lg;
    }

    &__excerpt,
    &__more {
      @apply text-base;
    }
  }

  @screen lg {
    &__excerpt,
    &__more {
      @apply text-lg;
    }
  }
}
