.callouts {
  @apply text-center;

  &__title {
    @apply mb-6;
  }

  &__intro {
    @apply max-w-3xl mx-auto my-6;
  }

  &__callouts {
    @apply flex justify-between flex-wrap;
    @apply mt-16;
  }

  .container--dark-bg & {
    @apply text-standard-white;
  }
}

.callout {
  @apply p-4;
  @apply w-1/4;
  @apply font-secondary;
}
