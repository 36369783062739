.stats {
  &__title {
    @apply text-2xl text-center;
    @apply mb-8;
  }

  &__stats-container {
    @apply flex flex-col items-center;
  }

  @screen md {
    &__title {
      @apply text-3xl mb-12;
    }

    &__stats-container {
      @apply flex-row justify-center flex-wrap;
    }
  }
}

.stat {
  @apply text-lg text-center;
  @apply px-2;

  &__inner {
    max-width: 20rem;
    @apply mx-auto;
  }

  &__plain-text {
    @apply mb-2;
  }

  &__stat {
    @apply font-primary font-bold text-5xl leading-none;
  }

  &:not(:last-of-type) {
    @apply pb-6 mb-8;
    @apply border-0 border-b border-solid border-standard-black;
  }

  @screen md {
    @apply w-auto py-4;
    flex-basis: 50%;

    &__inner {
      @apply text-xl;
    }

    &__stat {
      @apply text-5xl;
    }

    &:not(:last-of-type) {
      @apply mb-0 pb-0;
      @apply border-b-0 border-r;
    }
  }

  @screen lg {
    &__inner {
      @apply text-2xl;
    }
  }
}

.container--dark-bg {
  .stats {
    @apply text-standard-white;
  }

  .stat {
    @apply border-standard-white;
  }
}
